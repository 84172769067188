import { Component, Inject, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfCoupon from "@/vue/domain/df-coupon";
import DfUser from "@/vue/domain/user/df-user";

@Component({
  components: {
    DfHeaderMobile: () => Utils.externalComponent2("df-header-mobile"),
    DfStoreCard: () => Utils.externalComponent2("df-store-card"),
    DfCardCoupon: () => Utils.externalComponent2("df-card-coupon"),
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfPageAccountCoupon extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;
  private busy = false;
  private galleryLoading = true;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get activedCoupon(): number {
    return this.couponList.filter((coupon: DfCoupon) => coupon.selected).length;
  }

  get clubCardNumber(): DfUser {
    return Utils.getPropertyValue(this.currentUser, Utils.PROPERTY_USER_CAMI_CARD_NUMBER, "STRING");
  }

  get currentUser(): DfUser {
    return this.$store.getters.user;
  }

  get checkActionActived(): boolean {
    return this.couponList.findIndex((coupon: DfCoupon) => coupon.checked) > -1;
  }

  get couponList(): Array<DfCoupon> {
    return this.$store.getters.couponList;
  }

  set couponList(couponList: Array<DfCoupon> ) {
    this.$store.dispatch("setCouponList", couponList);
  }

  get massiveActivation(): boolean {
    const coupon: DfCoupon = this.couponList.find((coupon: DfCoupon) => coupon.checked);
    return coupon && !coupon.selected;
  }

  beforeMount() {
    if (Utils.enableSaviCoupon()) {
      console.log("SMT: Savi coupon enabled");
    } else if (Utils.enableLoyalGuruCoupon() && this.couponList.length === 0) {
      this.getCoupons();
    } else if (!Utils.enableLoyalGuruCoupon()) {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  mounted() {
    if (Utils.enableSaviCoupon()) {
      const newScript = document.createElement("script");
      newScript.src = Utils.getSaviCouponScriptUrl();
      this.$el.appendChild(newScript);
      //(<any>window).valassis.expand(); //funziona solo la prima volta
    }
  }

  private checkCoupon(coupon: DfCoupon) {
    coupon.checked = !coupon.checked;
    if (coupon.checked) {
      this.couponList = this.couponList.map((item: DfCoupon) => {
        item.checkDisabled = item.selected !== coupon.selected;
        return item;
      });
    } else if (!this.checkActionActived) {
      this.couponList = this.couponList.map((item: DfCoupon) => {
        item.checkDisabled = false;
        return item;
      });
    }
  }

  private getCoupons() {
    this.busy = true;
    this.service
      .getVoucherAll({
        status: DfCoupon.STATUS_AVAILABLE,
        storeAlias: this.currentStore.alias,
      })
      .then((coupons: Array<DfCoupon>) => {
        this.couponList = coupons;
        this.busy = false;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
        this.busy = false;
      });
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private massiveCheckReset() {
    this.couponList = this.couponList.map((coupon: DfCoupon) => {
      coupon.checked = false;
      coupon.checkDisabled = false;
      return coupon;
    });
  }

  private massiveUpdate(coupon: DfCoupon) {
    const action: string = this.massiveActivation ? DfCoupon.ACTION_SELECT : DfCoupon.ACTION_DESELECT;
    const codes: Array<string> = this.couponList.filter((coupon: DfCoupon) => coupon.checked).map((coupon: DfCoupon) => coupon.code);
    this.updateCoupon(action, codes);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private toggleCoupon(coupon: DfCoupon) {
    const action: string = coupon.selected ? DfCoupon.ACTION_DESELECT : DfCoupon.ACTION_SELECT;
    this.updateCoupon(action, [coupon.code]);
  }

  private updateCoupon(action: string, codes: Array<string>) {
    this.busy = true;
    this.service
      .selectVoucher({
        action: action,
        codes: codes,
      })
      .then(() => {
        this.getCoupons();
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
        this.busy = false;
      });
  }
  /*
  private getConsumerData(loginRequired: boolean, setConsumerData: any) {
    console.log("SMT: Call getConsumerData internal");
    setConsumerData("[Ask savi for this value]");
  }

  private galleryLoaded(data: any) {
    console.log("SMT: Call galleryLoaded internal", data);
    this.galleryLoading = false;
  }

  private getConsumerData(loginRequired: boolean, setConsumerData: string) {
    if (confirm('Would you like to provide login data?')) {
      setConsumerData('[Ask savi for this value]');
      document.getElementById('consumerLoginStatus').innerText = 'logged-in';
    } else if (loginRequired) {
      alert('Printing is not possible');
    }
  }
*/
}
