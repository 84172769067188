import { render, staticRenderFns } from "./df-page-account-coupon.vue?vue&type=template&id=48f5fcf6&scoped=true&"
import script from "./df-page-account-coupon.ts?vue&type=script&lang=js&"
export * from "./df-page-account-coupon.ts?vue&type=script&lang=js&"
import style0 from "./df-page-account-coupon.vue?vue&type=style&index=0&id=48f5fcf6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f5fcf6",
  null
  
)

export default component.exports